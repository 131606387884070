import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class IconsModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconResolver((name, namespace) => {
      return sanitizer.bypassSecurityTrustResourceUrl(
        `assets/app-icons/${namespace ? namespace + '/' : ''}${name}.icon.svg`
      );
    });
  }
}
